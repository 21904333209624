import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faXmark, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import "./chatWidget.css";

// Assets
import agnesImage from "../assets/img/ChatBot.png";
import ShortSweet from "../assets/img/Short&Sweet.svg";
import BeSpecific from "../assets/img/Specefic.svg"
import Respectful from "../assets/img/Respectfula.svg"
import sendButton from "../assets/img/SendButton.svg"
import chatButton from "../assets/img/ChatButton.svg"
import BackButton from "../assets/img/BackButton.svg"

const ChatWidget = ({ apiUrl }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isWaiting, setIsWaiting] = useState(false);
    const chatBodyRef = useRef(null);

    const toggleChatWindow = () => {
        setIsOpen(!isOpen);
    };

    const closeChatWindow = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && isOpen) {
                closeChatWindow();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen]);

    const sendMessage = async () => {
        if (!inputValue.trim() || isWaiting) return;

        const userMessageId = Date.now();
        const loadingMessageId = userMessageId + 1;

        appendMessage('', inputValue, 'user', userMessageId);
        appendMessage('', '...', 'agnes loading', loadingMessageId);
        setInputValue('');
        setIsWaiting(true);

        try {
            const response = await axios.post(`${apiUrl}/process`, { question: inputValue });

            if (response.status === 202) {
                const { query_id } = response.data;

                let attempts = 0;
                const maxAttempts = 300;  // 5 minutos (300 segundos)
                const pollInterval = 2000;  // 2 segundos entre cada intento

                const pollForResponse = async () => {
                    try {
                        const pollResponse = await axios.get(`${apiUrl}/get_response/${query_id}`);

                        if (pollResponse.status === 200) {
                            const { response } = pollResponse.data;
                            updateMessage(loadingMessageId, '', response, 'agnes');
                            setIsWaiting(false);

                            // Crear y guardar el log BBDD
                            const newLog = {
                                question: inputValue,
                                answer: response,
                                timestamp: new Date().toISOString().slice(0, 19).replace('T', ' ')
                            };

                            try {
                                const logResponse = await axios.post('/php/addLog.php', newLog, {
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                });
                                console.log('Log guardado en la base de datos', logResponse.data);
                            } catch (error) {
                                console.error('Error al guardar el log:', error.response ? error.response.data : error.message);
                            }
                            ///////////////////////////////    

                        } else if (pollResponse.status === 202) {
                            if (attempts < maxAttempts) {
                                attempts++;
                                setTimeout(pollForResponse, pollInterval);
                            } else {
                                updateMessage(loadingMessageId, '', 'I\'m sorry, but it\'s taking longer than expected to process your request. Please try again.', 'error');
                                setIsWaiting(false);
                            }
                        } else {
                            throw new Error('Unexpected response from server');
                        }
                    } catch (error) {
                        updateMessage(loadingMessageId, 'Error', 'Error getting response: ' + error.message, 'error');
                        setIsWaiting(false);
                    }
                };

                pollForResponse();
            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (error) {
            updateMessage(loadingMessageId, 'Error', 'Could not connect to the API. Details: ' + error.message, 'error');
            setIsWaiting(false);
        }
    };

    const appendMessage = (sender, message, type, id) => {
        setMessages((prevMessages) => [...prevMessages, { id, sender, message, type }]);
        setTimeout(() => {
            if (chatBodyRef.current) {
                chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
            }
        }, 100);
    };

    const updateMessage = (id, sender, message, type) => {
        setMessages((prevMessages) =>
            prevMessages.map((msg) =>
                msg.id === id ? { ...msg, sender, message, type } : msg
            )
        );
        setTimeout(() => {
            if (chatBodyRef.current) {
                chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
            }
        }, 100);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !isWaiting) {
            sendMessage();
        }
    };

    return (
        <div>
            <div
                className={`chat-button-container ${isOpen ? 'active' : ''}`}
                onClick={toggleChatWindow}
            >
                <button className="chat-button">
                    <img src={chatButton} alt="" className='ChatButtonIcon' />
                </button>
                <span className="chat-label">Agnes AI</span>
            </div>
            <div className={`chat-window ${isOpen ? 'open' : ''}`}>
                <div className='title-container'>
                    <div className='title-left'>
                        <div className="chat-header">
                             
                        </div>
                    </div>
                    <div className='title-right'>
                        <button className="close-button" onClick={toggleChatWindow}>
                            <span>
                                <FontAwesomeIcon icon={faXmark} className='closeIcon-x' />
                                <img src={BackButton} alt="" className='closeIcon-chevron' />
                            </span>
                        </button>
                    </div>
                </div>

                <div className="chat-body" ref={chatBodyRef}>

                    <div className='AgnesInitialContainer'>
                        <img
                            src={agnesImage}
                            alt="Agnes Image"
                            className='agnesImage'
                        />
                        <h2>Chat with Agnes</h2>
                        <h2 className='alpha'> Alpha </h2>
                        <span>Learn about ENO and ask anything</span>


                        <div className='dataAgnes'>

                            {/* Shoort & Sweet */}
                            <div className='dataAgnesInfo'>
                                <div className="GridLeft">
                                    <h3>Shoort & Sweet</h3>
                                    <p>Keep your prompts concise</p>
                                </div>
                                <div className="AgnesRight">
                                    <img src={ShortSweet} alt="" className='AgnesIcon' />
                                </div>
                            </div>

                            {/* Be specific */}
                            <div className='dataAgnesInfo'>
                                <div className="GridLeft">
                                    <h3>Be specific</h3>
                                    <p>Avoid vague language</p>
                                </div>
                                <div className="AgnesRight">
                                    <img src={BeSpecific} alt="" className='AgnesIcon' />
                                </div>
                            </div>

                            {/* Respectful Chat */}
                            <div className='dataAgnesInfo'>
                                <div className="GridLeft">
                                    <h3>Respectful Chat</h3>
                                    <p>Use appropiate language</p>
                                </div>
                                <div className="AgnesRight">
                                    <img src={Respectful} alt="" className='AgnesIcon' />
                                </div>
                            </div>
                        </div>
                    </div>

                    {messages.map((msg) => (
                        <div key={msg.id} className={`chat-message ${msg.type}`}>
                            <strong>{msg.sender} </strong>
                            <span>{msg.message}</span>
                        </div>
                    ))}
                </div>
                <div className="chat-footer">
                    <input
                        type="text"
                        className="chat-input"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder='Send your message...'
                        disabled={isWaiting}
                    />
                    <button className="chat-send-button" onClick={sendMessage} disabled={isWaiting}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatWidget;